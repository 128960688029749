.shopping{
    &--header{
        padding: 1rem;
        display: flex;
        > *{
            display: flex;
            flex: 1 1 auto;
        }
    }
    &--body{
        display:  flex;
        flex-direction: column;
        gap: 0.25rem;
        .item{
            background:#272724;
            border-radius: 8px;
            padding: 0.5rem 1rem;
            display: flex;
            gap: 0.75rem;
            align-items: center;
            color: #fff;
            &:hover{
                background-color: #2c2e31 !important;
                .item--action{
                    i.fa-trash-alt{
                        color: rgb(255, 43, 43);
                    }
                }
            }
            > div {
                display: flex;
                flex: 1 1 auto;
            }
            &--details{
                align-items: center;
                gap: 1rem;
                img{
                    width: 90px;
                    height: 75px;
                    object-fit: contain;
                }
                h3{
                    font-size: 1rem;
                    color: #fff;
                    margin-bottom: 0.5rem;
                    font-weight: 600;
                }
                .tags{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 0.25rem;
                    span{
                        display: flex;
                        gap: 0.4rem;
                        align-items: center;
                        justify-content: flex-start;
                        background: rgb(61, 58, 58);
                        width: fit-content;
                        padding:  0.15rem 0.5rem;
                        border-radius: 4px;
                        font-size: 14px;
                        font-weight: 600;
                        color: #FBB040;
                        img{
                            width: 15px;
                            height: auto;
                        }
                        span{
                            padding: 0;
                        }
                    }
                }
            }
            .buyBtn-group{
                display: flex;
                span{
                    font-size: 1.25rem;
                }
            }
            &--action{
                flex: 0 !important;
                .action{
                    &--item{
                        width: 40px;
                        height: 40px;
                        border: none;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;;
                        background: rgb(61, 58, 58);
                        svg{
                            width:20px;
                            height: 20px;
                            fill: rgb(170, 170, 170);
                        }
                        &:hover{
                            background: rgb(95, 95, 95);
                            svg{
                                fill: rgb(255, 35, 35);
                            }
                        }
                    }
                }
            }
            &--price{
                display: flex;
                flex-direction: column;
                h5{
                    margin: 0;
                    font-size: 1.2rem;
                    color: #fff;
                    img{
                        margin-right: 0.45rem;
                    }
                    span{
                        font-size: 1rem ;
                    }
                }
            }
            &--others{
                display: flex;
                gap: 3rem;
                align-items: center;
                justify-content: flex-end;
                padding-right: 2rem;
            }
        }
    }
}

.page{
    &--header{
        &.shopping{
            &--header{
                padding: 1rem;
                background:#272724;
                border-radius: 6px;
                display: block;
                h3{
                    font-size: 1.25rem;
                    font-weight: 600;
                    color: #fff;
                    margin-bottom: 0.25rem;
                }
            } 
        }
    }
}
.shopping{
    &--row{
        display: flex;
        gap: 2rem;
        align-items: flex-start;
        justify-content: center;
        .shopping--card{
            flex:  1 1 auto;
        }
    }
}

.buy--card{
    background:#272724;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    gap: 1rem;
    flex: 1 1 auto;
    color: #fff;
    max-width: 360px;
    width: 100%;
}
.buy--info{
    text-align: center;
    color: #fff;
    h3{
        color: #fff;
        img{
            margin-right: 0.5rem;
        }
        span{
            font-size: 1rem ;
            color: #fff;
        }
    }
} 