.back--btn{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    color: #e0e0e0;
    cursor: pointer;
    &:hover{
        color: #e0e0e0;
        text-decoration: none;
    }

}
.page{
    &--header{
    }
    &--body{
        margin-bottom: 5rem;
        > .nft--row{
            @media (max-width:520px) {
                flex-direction: column;
            }
        }
        .nft{
            &--row{
                display: flex;
                gap: 3rem;
                @media (max-width:1024px){
                    gap: 1rem;
                }
                > div{
                    flex: 1 1 auto;
                }
            }
            &--image{
                .image--box{
                    width: 100%;
                    border-radius: 8px;
                    background-color: #3d3a3a;
                    background-image: radial-gradient(white, #b87f7f00);
                    img{
                        transform: scale(0.9);
                        width: 100%;
                        transition: 0.2s all ease-in-out;
                        min-height: 487px;
                         @media (max-width:1024px){
                             min-height: auto !important;
                         }
                    }
                    &:hover{
                        img{
                            transform: scale(1);
                        }
                    }
                }
            }
            &--deatils{
                max-width: 50%;
                
                @media (max-width:768px){
                    padding: 1rem;
                    @media (max-width:520px) {
                        max-width: 100%;
                        padding: 0.5rem;
                    }
                }
                h1{
                    color: #fff;
                    font-weight: 600;
                    margin-bottom: 2rem;
                }
                > p{
                    margin-bottom: 2rem;
                }
                .nft--row{
                    display: flex;
                    gap: 1rem;
                    @media (max-width:768px){
                        flex-wrap: wrap;
                        > div{
                            max-width: 100% !important;
                        }
                    }
                    > div{
                        max-width: 50%;
                        flex: 1 1 auto;
                    }
                    .addToCart{
                        &--btn{
                            display: flex;
                            gap: 0.5rem;
                            align-items: center;
                            justify-content: center;
                        }
                        &--action{
                            border: 1px solid rgb(119, 119, 119);
                            display: flex;
                            gap: 1rem;
                            align-items: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 100px;
                            background-color: #1E1F1D;
                            padding: 0.4rem;
                            .buyBtn-group{
                                display: flex;
                                gap: 1rem;
                                align-items: center;
                                @media (max-width:1024px){
                                    gap: 0.25rem ;
                                }
                            }
                        }
                    }
                    &.info--row{
                        margin-bottom: 2rem;
                        > div{
                        }
                        .available{
                            background-color: #28282e;
                            padding: 1rem;
                            border-radius: 8px;
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            @media (max-width:1024px){
                                gap: 0.2rem ;
                            }
                            h4{
                                color: #fff;
                                margin: 0;
                                font-weight: 600;
                                display: flex;
                                gap: 0.5rem;
                                align-items: center;
                                @media (max-width:1024px){
                                    gap: 0.1rem ;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}