@mixin lineEffect {
    &::before{
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        background: linear-gradient(to right, #FBB040 0%, #FFDE17 100%);
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.claimtab{
    &--conatiner{
    }
    &--group{
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        gap: 1rem;
        align-items: center;
        border-bottom:1px solid #efefef;
        margin-top:2rem;
    }
    &--item{
        padding: 0.5rem 1rem;
        position: relative;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        &:hover{
            @include lineEffect;
        }
        &.active{
            @include lineEffect;
        }
    }
    &--content{
        padding: 2.5rem 0;
    }
}