.map_container {
    width: 100%;
    height: 620px;
    background-image: url("../../../images/map-img.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .card {
        border: none;
    }
    &-itme {
        padding: 2rem;
        display: flex;
        justify-content: space-around;
        position: relative;
        height: 100%;
        .card {
            width: 90px;
            position: absolute;
            padding: 0;
            border-radius: 0;

            background: transparent linear-gradient(62deg, #4d4d4d, #000);
            &-body {
                padding: 4px;
            }
            &-text {
                font-size: 10px;
                color: white;
                text-align: center;
            }
            &-img-top {
                border-radius: 0;
            }
        }
        .card-1 {
            top: 80px;
            left: 36px;
        }
        .card-2 {
            right: 288px;
            top: 80px;
        }
    }
    &-itme2 {
        padding: 2rem;
        display: flex;
        justify-content: space-around;
        position: relative;
        height: 100%;
        .card {
            width: 90px;
            position: absolute;
            border-radius: 0;
            padding: 0;

            background: transparent linear-gradient(62deg, #4d4d4d, #000);
            &-body {
                padding: 4px;
            }
            &-text {
                font-size: 10px;
                color: white;
                text-align: center;
            }
            &-img-top {
                border-radius: 0;
            }
        }
        .card-3 {
            top: 55px;
            left: 194px;
        }
        .card-4 {
            top: 55px;
            left: 445px;
        }
        .card-5 {
            top: 24px;
            left: 603px;
        }
    }
    &-itme3 {
        padding: 2rem;
        display: flex;
        justify-content: space-around;
        position: relative;
        height: 100%;
        .card {
            width: 90px;
            position: absolute;
            border-radius: 0;
            padding: 0;

            background: transparent linear-gradient(62deg, #4d4d4d, #000);
            &-body {
                padding: 4px;
            }
            &-text {
                font-size: 10px;
                color: white;
                text-align: center;
            }
            &-img-top {
                border-radius: 0;
            }
        }
        .card-1 {
            top: 124px;
            left: 475px;
        }
        .card-2 {
            top: -34px;
            right: 100px;
        }
    }
}
