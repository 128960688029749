.team{
    &--group{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        justify-content: space-between;;
        width: 100%;
        gap: 4rem;
    }
    &--item{
        box-shadow: 3px 5px 20px 3px rgba(0,0,0,0.1);
        border-radius: 40px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        display: flex;
        gap: 1rem;
        align-items: center;
        img{
            width: 100px;
        }
        > div{
            padding: 1rem 0.5rem;
            h3{
                font-size: 1.25rem;
                font-weight: 600;
                color: rgb(58, 58, 58);
            }
        }
    }
}

.advisor{
    &--group{
        display: grid;
        grid-template-columns: repeat(5,1fr);
        justify-content: space-between;;
        width: 100%;
        gap: 4rem;
    }
    &--item{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        img{
            width: 150px;
            margin-bottom: 1rem;
        }
        h3{
            font-weight: 600;
            font-size: 1rem;
        }
    }
}