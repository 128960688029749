.placeholderLoader{
    background-color: #eee;
    border-radius: 5px;
    padding: 0.75rem;
    position: relative;
    overflow: hidden;
    min-width: 80px;
    &::before{
        content: '';
        display: block;
        width: 70%;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 49%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        top: 0;
        left: 0;
        transform: skew(-25deg);
        animation: mover 1s infinite;
        @keyframes mover {
            0%{
                transform: skew(-25deg) translateX(-100%);
            }
            100%{
                transform: skew(-25deg) translateX(200%);
            }
        }
    }
    &.full{
        width: 100% !important;
    }
    &.dark{
        background-color: #323239;
        &::before{
            height: 120%;
            background: linear-gradient(90deg, #323239 0%, #3a3a41 49%, #323239 100%);
        }
    }
}