.cricket-bg {
    width: 100%;
    height: 100%;
    background-image: url("../../../images/cricket-bg.png");
    background-size: 105% 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 1rem;
    @media screen and (max-width: 768px) {
        padding: 0;
    }

    .cricket {
        &__lands {
            width: 100%;
            height: 80vh;
            background-image: url("../../../images/lands-pic/cricket-lands.png");
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
        }
        &__lands__list {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            position: relative;
        }
        &__lands__itme {
            position: relative;
            h2 {
                font-size: 26px;
            }
            .itme1 {
                width: 140px;
                height: 140px;
                background: #0c0c0c;
                position: absolute;
                padding: 0.75rem;
                top: -57px;
                left: 170px;
                @media screen and (min-width: 1440px) {
                    top: -70px;
                    left: 200px;
                }
            }
            .itme2 {
                width: 100px;
                height: 100px;
                position: absolute;
                background: #0c0c0c;
                color: #fff !important;
                top: 90px;
                left: 30px;
                text-align: center;
                padding-top: 1.5rem;
                h2 {
                    font-size: 20px;
                    color: #fff !important;
                    span {
                        font-weight: bold;
                        font-size: 24px;
                    }
                }
                @media screen and (min-width: 1440px) {
                    top: 100px;
                    left: 30px;
                }
            }
            .itme3 {
                width: 80px;
                height: 80px;
                position: absolute;
                background: #0c0c0c;
                color: #fff !important;
                top: -240px;
                left: -130px;
                text-align: center;
                padding-top: 1.5rem;
                h2 {
                    font-size: 16px;
                    color: #fff !important;
                    span {
                        font-weight: bold;
                        font-size: 20px;
                    }
                }
                @media screen and (min-width: 1440px) {
                    top: -240px;
                    left: -130px;
                }
            }
            .itme4 {
                width: 110px;
                height: 110px;
                position: absolute;
                background: #0c0c0c;
                top: -100px;
                left: -270px;
                text-align: center;
                padding-top: 1.5rem;
                h2 {
                    font-size: 20px;
                    color: #fff !important;
                    span {
                        font-weight: bold;
                        font-size: 24px;
                    }
                }
                // @media screen and (min-width: 1440px) {
                //     top: 0;
                //     left: 0;
                // }
            }
        }
        &__join {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 2rem;
            gap: 1.5rem;

            @media screen and (max-width: 768px) {
                gap: 1rem;
                padding-top: 0;
            }
        }
        &__join__btn {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            padding-top: 1rem;
            @media screen and (max-width: 768px) {
                padding: 0;
                gap: 0.5;
            }
            .join-btn {
                width: 230px;
                border: none;
                background-color: transparent;
                color: #fff;
                font-size: 26px;
                font-weight: bold;
                @media screen and (max-width: 768px) {
                    width: 160px;
                }
            }
            .view-btn {
                width: 230px;
                border: none;
                background-color: transparent;
                color: #fff;
                font-size: 26px;
                font-weight: bold;
                @media screen and (max-width: 768px) {
                    width: 160px;
                }
            }
        }
        &__logo {
            width: 350px;
            text-align: center;
            @media screen and (max-width: 768px) {
                width: 250px;
            }
            img {
                width: 100%;
            }
        }
        &__player__con {
            background-image: url("../../../images/lands-pic/cricket-player.png");
            background-size: 100% 100%;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            padding: 20px;
            max-width: 1200px;
            height: 580px;
            position: relative;

            @media screen and (max-width: 768px) {
                transform: rotate(90deg);
                position: absolute;
                top: 169px;
                left: 52px;
                width: 634px;
                height: 582px;
            }
            @media screen and (max-width: 442px) {
                transform: rotate(90deg);
                position: absolute;
                top: 169px;
                left: -28px;
                width: 424px;
                height: 369px;
            }
            .video__player {
                max-width: 1140px;
                min-height: 500px;
                height: auto;
                position: relative;
                @media screen and (max-width: 1024px) {
                    max-width: 1080px;
                    min-height: 480px;
                }
                @media screen and (max-width: 768px) {
                    min-height: 313px;
                }
            }
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        &__container {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            @media screen and (max-width: 768px) {
                padding-top: 0;
            }
        }
    }
    .stadium-img {
        width: 800px;
        margin-top: 1rem;
        @media screen and (max-width: 768px) {
            width: 450px;
        }
    }
}
