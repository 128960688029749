.coming-soon {
    background-image: url(../../../images/staking-pic/coming-soon-BG.png);
    background-repeat: no-repeat;
    background-size: 103% 103%;
    background-position: center;
    padding: 2rem 0;
    @media (max-width: 768px) {
        padding: 1rem 0;
    }
    &__row {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6rem 0;
        @media (max-width: 768px) {
            padding: 2rem 0;
        }
    }
    &__pic {
        margin: 0 auto;
        max-width: 800px;
        width: 100%;
        @media (max-width: 768px) {
            width: 80%;
        }
    }
}

.price-calculator {
    padding: 2rem 0;
    background-image: url(../../../images/staking-pic/price-calculator-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 3rem 0;
    @media (max-width: 768px) {
        margin: 0;
    }
    &__row {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        padding: 1.5rem 2rem;
        @media (max-width: 768px) {
            padding: 1.5rem 1rem;
        }
    }
    &__title {
        h2 {
            color: #fdc210;
            font-weight: 600;
            @media (max-width: 768px) {
                font-size: 20px;
                text-align: center;
            }
        }
    }
}

.share-token {
    padding: 3rem 0;
    &__row {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
            flex-direction: column;
            gap: 1rem;
        }
    }
    &__col {
        width: 50%;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    &__img {
        width: 80%;

        text-align: right;
        @media (max-width: 768px) {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__content {
        padding: 1rem 4rem;
        @media (max-width: 1024px) {
            padding: 1rem 1rem;
        }
    }
    &__title {
        margin-bottom: 1rem;
        h2 {
            font-weight: 600;
            @media (max-width: 768px) {
                font-size: 20px;
                text-align: center;
            }
        }
    }
    &__dec {
        padding-bottom: 2rem;
        p {
            margin-bottom: 1rem;
            color: #474747;
            @media (max-width: 768px) {
                text-align: center;
            }
        }
    }
}

.on-platform {
    padding: 6rem 0;
    @media (max-width: 768px) {
        padding: 2rem 0;
    }
    &__row {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 0rem;
        @media (max-width: 768px) {
            padding: 1rem 0rem;
            flex-direction: column;
            gap: 1rem;
        }
    }
    &__col {
        width: 50%;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    &__content {
        padding: 1rem 6rem;
        @media (max-width: 1024px) {
            padding: 1rem 1rem;
        }
        p {
            margin-bottom: 1rem;
            color: #474747;
            @media (max-width: 768px) {
                text-align: center;
            }
        }
    }
}
