.discover-card .discover-card-title{
    /* min-height: 52px; */
    display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
    font-size: 18px !important;
}
.header.header{
    backdrop-filter: none !important;
    background-color: #fff;
}
.discover-card .card--header{
    margin-bottom: 1rem;
}
.discover-card .card--header h4{
    margin-bottom: 0.3rem !important;
}

.aqarchain-2-footer .social-nav .footer-link .fab{
    border-color: #1E282F !important;
}

.aqarchain-2-footer .aqarchain-2-foote-row-1 {
    border-bottom: 1px solid #ced6db !important;
}
#landing-page-section_3-container{
     padding: 20px 0 100px 0 !important;
     
}
@media (max-width: 768px){
    #landing-page-section_3-container{
        padding: 20px 0 20px 0 !important;
        
   }


    
}
#landing-page-why-aqarchain-section_3 .service-block:hover{
        transform: translateY(-30px) !important;
}
.content-header{
    font-size: 38px !important;

}
.footer {
    background: #f2f7ff !important;
}
.footer .social-nav{
    display: flex;
    gap: 1rem;
    max-width: 300px;
    width: 100%;
    justify-content: end;  
}
@media (max-width: 768px){
    .content-header{
    font-size: 24px !important;  
    }
    .card-title{
        font-size: 20px; 
    }
    .footer .social-nav{
      justify-content: center;  
    }

}
.footer .social-nav li a{
    display: flex;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border: 1px solid rgb(82, 82, 82);
    border-radius: 10px;
}
 .footer .social-nav li a i{
     font-size: 1.5rem;
 }
 #property-detail-header h1{
        font-size: 2rem;
 }
#property-detail-meta-middle h1{
    font-size: 1.5rem;
}
#property-detail-meta-middle h2{
    font-size: 1.2rem;
}
#property-detail-meta-bottom span{
    font-size: 1.2rem;
}

#property-detail-amenities{
    display: grid !important;
    row-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
#property-detail-tab-links .nav-link{
    cursor: pointer !important;
    display: block;
    position: relative;
}
#property-detail-tab-links .nav-link::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, #FBB040 0%, #FFDE17 100%);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}
#property-detail-tab-links .nav-link:hover::before{
    visibility: visible;
    transform: scaleX(1);
}
 #property-detail-tab-links .nav-link.active{
     background: transparent;
    border-bottom: none !important;
 }
 #property-detail-tab-links .nav-link.active::before{
    visibility: visible;
    transform: scaleX(1);
}

header.header .navbar-nav .nav-item .nav-link{
    font-size: 1.2rem !important;
}

.react-photo-gallery--gallery{
    height: 100%;
}

.react-photo-gallery--gallery > div {
    height: 100%;
}

.react-photo-gallery--gallery img{
    display: none !important;
}

.react-photo-gallery--gallery img:first-child{
    display: block !important;
}

.video-react-big-play-button{
    top:50% !important;
    left:50% !important;
    transform: translate(-50%, -50%);
}

.property-clock-wrap .property-clock{
    padding: 0 !important;
}
.property-clock-wrap{
    background: none !important;
}
.CircularProgressbar-text{
    font-weight: 600;
    color: #000 !important;
}

.referalBox{
    border: 1px solid #fdc810;
    padding: 1rem;
    background: #fffce7;
    border-radius:10px;
    margin-bottom: 2rem;
}

.referralBtn{
    font-size: 14px !important;
    padding: 0.5rem 1rem !important;
    margin-left: auto;
    display: block !important;
    margin-bottom: 1.5rem;
}

.claimBtn{
    font-size: 14px !important;
    padding: 0.5rem 1rem !important;
}

.claim--container{
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}

.claim--item{
    flex: 1 1 auto;
    padding:0.75rem 1rem;
    border-radius: 10px;
    background-color: #F0F2F7;
}
.claimable{
    border: 1px solid #fdc810;
    background: #fffce7;
}

.claim--item > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
 
.claim--item > div .btn{
    cursor: pointer;
}

.claim--item h2{
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 0 !important;
}

.claim--item h4{
    margin: 0;
}

.durationText{
    text-transform: capitalize;
    margin-top: 1rem;
    text-align: center;
}
.durationText span{
    font-weight: 600;
}
.amenity--img{
    border-radius: 6px;
    background-color: #fff;
    padding: 0.5rem;
    width: fit-content;
    margin: 0 auto;
}


.registration-artwork-top{
    padding: 2rem 10rem; 
}
#user-registration-section_1-signup-step6 .has-box-shadow{
    padding: 6rem 3rem;
    margin: 0 1rem;
}
@media (max-width: 1200px){
.registration-artwork-top{
    padding: 2rem 4rem; 
}
}
@media (max-width: 1024px){
.registration-artwork-top{
    padding: 3rem 1rem 2rem 1rem; 
}
}
@media (max-width: 768px){
    .registration-artwork-top{
    padding: 3rem 1rem 2rem 1rem; 
    }
    .registration-artwork-top h2{
        font-size: 20px;
    }
    #user-registration-section_1-signup-step6 .has-box-shadow{
        padding: 4rem 2rem;
        margin: 0;
    }
    #user-registration-section_1-signup-step6 .has-box-shadow .form-heading h3{
        font-size: 20px;
    }
}

iframe:not(.map){
    z-index: -2147483647 !important;
}

