.dataList{
    padding-left: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 0.25rem;
    margin: 1rem 0;
    margin-bottom: 1.5rem;
    li{
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 0.5rem;
        position: relative;
        align-items: center;
        padding: 0.5rem;
        padding-left: 1rem;
        border-bottom: 1px solid rgb(241, 241, 241);
        &:last-child{
            border-bottom: none;
        }
        &::before{
            content: "";
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #374047;
            position: absolute;
            left: 0;
        }
        span{
            color: #FBB040;
            font-weight: 600;
        }
    }
}