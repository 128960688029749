.real-estate-nft {
    background-image: url(../../../images/login/Polygon-bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #000;
    padding: 2rem 0;
    @media (max-width: 1024px) {
        padding: 4rem 0;
    }
    &__row {
        display: flex;
        align-items: center;
        @media (max-width: 1024px) {
            flex-direction: column;
            gap: 2rem;
        }
    }
    &__col {
        width: 50%;
        @media (max-width: 1024px) {
            width: 100%;
        }
        .real {
            &__content {
                padding: 0 4rem 0 2rem;
                color: #ffffff;
                @media (max-width: 786px) {
                    padding: 0 1rem 0 1rem;
                }
                h1 {
                    color: #ffffff;
                    font-weight: 600;
                    font-size: 32px;
                    @media (max-width: 768px) {
                        font-size: 20px;
                    }
                }
                p {
                    margin-bottom: 2rem;
                }
            }
            &__video {
                &-content {
                    text-align: center;
                    p {
                        font-weight: 600;
                        color: #ffffff;
                    }
                }
                &-box {
                    max-width: 500px;
                    margin: 0 auto;
                    .video {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.claim-property {
    padding: 4rem 0 0 0;

    &__row {
        display: flex;
        align-items: center;
        background-color: #f8f8fa;
        border-radius: 35px 35px 0 0;
        padding: 2rem 2rem 0;
        @media (max-width: 1024px) {
            flex-direction: column;
            gap: 1rem;
            padding: 0rem 1rem 2rem 1rem;
        }
    }
    &__col {
        width: 50%;
        @media (max-width: 1024px) {
            width: 100%;
        }
        .claim {
            &__content {
                padding: 0 6rem 0 2rem;
                @media (max-width: 1024px) {
                    padding: 0 1rem 0 1rem;
                }
                h1 {
                    color: #203152;
                    font-weight: 600;
                    font-size: 32px;
                    @media (max-width: 768px) {
                        font-size: 20px;
                    }
                }
                p {
                    margin-bottom: 2rem;
                }
            }
        }
        .polygon-box {
            max-width: 630px;

            background-image: url(../../../images/login/Polygon-art-bg.png);
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 3rem;
            box-shadow: 0px 0px 0px 0px #000;
            @media (max-width: 1024px) {
                margin: 0 auto;
                padding: 3rem 4rem;
            }
        }
        .polygon-art {
            max-width: 300px;
        }
    }
}

.share-property {
    padding: 0 0 3rem;
    &__row {
        display: flex;
        align-items: center;
        background-color: #f8f8fa;
        border-radius: 0 0 35px 35px;
        padding: 2rem;
        @media (max-width: 1024px) {
            flex-direction: column;
            gap: 1rem;
            padding: 2rem 1rem 2rem 1rem;
        }
    }
    &__col {
        width: 50%;
        @media (max-width: 1024px) {
            width: 100%;
            &.order-col {
                order: 2;
            }
        }
        .claim {
            &__content {
                padding: 0 2rem;
                @media (max-width: 1024px) {
                    padding: 0 1rem 0 1rem;
                }
                h1 {
                    color: #203152;
                    font-weight: 600;
                    font-size: 32px;
                    @media (max-width: 768px) {
                        font-size: 20px;
                    }
                }
                p {
                    margin-bottom: 2rem;
                }
            }
        }
        .polygon-box {
            max-width: 600px;
            margin-left: auto;
            background-image: url(../../../images/login/Polygon-art-bg.png);
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4rem;
            box-shadow: 0px 0px 0px 0px #000;
            @media (max-width: 1024px) {
                margin: 0 auto;
                padding: 4rem;
            }
        }
        .polygon-art {
            width: 320px;
        }
    }
}
