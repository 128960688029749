.pageHeader{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem 0;
    @media (max-width:768px){
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;   

        >div{
            width: 100%;
            >div{
                width: 100%;
            }
        }     
    }
}

.tabGroup{
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0;
    margin: 0;
}

.tabItme{
    list-style: none;
    padding: 0.75rem 1rem;
    background-color: #293238;
    border-radius: 6px;
    color:#d8d8d9;
    cursor: pointer;
    font-weight: 600;
    min-width: 140px;
    text-align: center;
    &:hover{
        background-color: #2c363d;
    }
    
}
.active{
    background-image: linear-gradient(to right, #FBB040 0%, #FFDE17 100%);
    color: #191e22;
}

.searchBox{
    margin: 0 !important;
}