.title{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    h4{
        margin: 1rem 0;
        margin-top: 2rem;
        font-size: 1.25rem;
        font-weight: 600;
        color: #fff;
    }
}
.card{
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 300px;
    background-color: #28282e;
    .cardheader{
        display: flex;
        justify-content: space-between;
    }
    .cardItem{
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
        border-bottom: 1px solid #3d3a3a;
        position: relative;
        a{
            color: #868E9C;
            &:hover{
                color: #F4B13C;
            }
        }
        .action{
            background-color: #303038;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            padding: 0.25rem 0.75rem;
            border-radius: 4px;
            display: flex;
            gap: 0.5rem;
            span{
                font-weight: 600;
                font-size: 0.85rem;
                color: #fff;
            }
        }
        svg{
            width: 20px;
            height: 20px;
            path{
                fill: #F4B13C;
            }
        }
    }
}