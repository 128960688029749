.staking {
    background-color: #ffeece;
    width: 100%;
    // background-image: url("../../../../assets/images/staking-pic/staking-bg-2.png");
    // background-repeat: no-repeat;
    // background-size: 100%;
    // background-position: bottom;
    // overflow: auto;
    // min-height: 100vh;
    padding-bottom: 4rem;
    // overflow: auto;
    &__list {
        width: 100%;
        padding: 3rem 0;
        padding-top: 4rem;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 1.5rem;
        @media (max-width: 768px) {
            padding-top: 2rem;
        }
        @media (max-width: 480px) {
            padding-top: 2rem;
        }
    }
    &__itme {
        position: relative;
        // max-width: 400px;
        .card {
            padding: 0.5rem;
            padding-bottom: 1rem;
            border-radius: 15px;
            border: none;
            box-shadow: 0px 30px 30px #00000017;
            position: relative;

            &-header {
                text-align: center;
                background-color: transparent;
                border-bottom: 1px solid rgba(0, 0, 0, 0.35);
                padding: 1rem 0;
                h3 {
                    font-size: 20px;
                    font-weight: 700;
                    color: #374047;
                }
            }
            &-img {
                margin: 1rem auto;
                width: 120px;
                img {
                    width: 100%;
                }
            }
            .info {
                position: absolute;
                right: 10px;
                top: 10px;
                cursor: pointer;
                z-index: 1;
                transition: all 0.3s ease-in-out;
                &:hover {
                    opacity: 0;
                    width: 96%;
                    height: 98%;
                    text-align: right;
                    ~ .info-card {
                        opacity: 1;
                        visibility: visible;
                        z-index: 999;
                    }
                }
            }
            &-body {
                padding: 1rem 0.75rem;
                .btn {
                    width: 100%;
                    margin-top: 1rem;
                    border-radius: 50px;
                    padding: 0.65rem;
                    background-color: #e9eaeb;
                    font-weight: 600;
                    font-size: 16px;
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
            .info-card {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #fff;
                top: 0;
                left: 0;
                border-radius: 10px;
                padding: 1rem;
                text-align: center;
                opacity: 0;
                pointer-events: none;
                transition: all 0.3s ease-in-out;

                &__header {
                    padding-top: 5rem;
                    background-color: transparent;
                }
                &__body {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
                    p {
                        font-weight: 300;
                        font-size: 20px;
                        margin-bottom: 0.5rem;
                    }
                    h4 {
                        font-weight: 700;
                        font-size: 26px;
                        margin-bottom: 0.5rem;
                    }
                }
                &__footer {
                    padding: 2rem 0;
                    .metamask {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 1rem;
                        margin-bottom: 1rem;
                        h6 {
                            color: #f6851b;
                            padding: 0;
                            margin: 0;
                            font-weight: 500;
                        }
                    }
                    a {
                        text-decoration: none;
                    }
                }
            }
            &-text {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    p {
                        padding: 0;
                        margin: 0;
                        font-weight: 500;
                    }
                }
            }
            &-footer {
                background-color: transparent;
                padding: 0.75rem 0.5rem;
                border-top: 1px solid rgba(0, 0, 0, 0.125);
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    li {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                    }
                }
                p {
                    font-weight: 500;
                    color: #374047;
                }
            }
        }
    }
}

.form-check-input {
    border: 1px solid #fff;
    background-color: transparent;
    &:checked {
        background-color: #f6851b;
        border-color: #f6851b;
    }
    &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid #fff;
    }
}
.form-check-label {
    color: #fff;
    a {
        color: #fff;
    }
}
