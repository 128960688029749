.pools {
    background-color: #ffeece;
    width: 100%;
    background-image: url("../../../images/staking-pic/pools-bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    overflow: auto;
    min-height: 80vh;
    .stake {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        &__card {
            max-width: 500px;
            width: 100%;
            background-color: #fff;
            border-radius: 15px;
            box-shadow: 0px 30px 30px #00000017;
            padding: 1rem 2rem;
            margin-bottom: 1rem;
            @media (max-width: 480px) {
                padding: 1rem;
            }
        }
        &__card__header {
            display: flex;
            justify-content: center;
            flex-direction: column;
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 1.5rem;
            .text {
                text-align: center;
                img {
                    width: 150px;
                    margin-bottom: 1rem;
                }
                h4 {
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #374047;
                }
            }
            .back-btn {
                a {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    cursor: pointer;
                    border: none;
                    background-color: transparent;
                    padding: 0.25rem;
                    color: #374047;
                    text-decoration: none;
                    width: 140px;
                    font-weight: 500;
                }
                img {
                    width: 24px;
                }
            }
        }
        &__card__body {
            padding: 0 0 1rem 0;
        }
        .btn-group {
            width: 100%;
            background-color: lighten($color: #1e282e, $amount: 80);
            border-radius: 8px;
            margin: 0 0 1.5rem 0;

            // some default design Hide

            .btn-check {
                color: #374047;
                &:checked {
                    + .btn-outline-primary {
                        background-color: #1e282e;
                        color: #fff;
                        font-weight: 600;
                        &:focus {
                            box-shadow: none;
                        }
                    }
                    &:focus {
                        box-shadow: none;
                    }
                }
                &:focus {
                    box-shadow: none;
                    + .btn-outline-primary {
                        box-shadow: none;
                    }
                }
            }
            .btn-outline-primary {
                &:hover {
                    background-color: transparent;
                    color: #374047;
                }
                &:focus {
                    box-shadow: none;
                }
                &:active {
                    background-color: transparent;
                    color: #374047;
                }
            }
            .btn {
                padding: 0.9rem 1rem;
                border: none;
                border-radius: 8px;
                color: #374047;
                font-size: 1rem;
                font-weight: 600;
                &:focus {
                    box-shadow: none;
                }
            }
        }
        &__card__text {
            padding: 1.5rem;
            position: relative;
            @media (max-width: 480px) {
                padding: 1.5rem 1rem;
            }
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 4px;
                background: linear-gradient(-270deg, #fbb040, #ffde17);
            }
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 4px;
                background: linear-gradient(-270deg, #fbb040, #ffde17);
            }
            .pera {
                color: #9b9b9b;
                font-size: 14px;
                margin-bottom: 1rem;
            }
            .balance {
                display: flex;
                justify-content: space-between;
                padding: 0;
                color: #374047;
                p {
                    font-weight: 600;
                }
                span {
                    font-size: 14px;
                }
            }
            .input-group {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0.75rem;
                box-shadow: 0px 0px 8px #00000017;
                border-radius: 8px;
                margin: 0.75rem 0;
                .form-control {
                    border: none;
                    background-color: transparent;
                    color: #374047;
                    font-size: 1rem;
                    font-weight: 600;
                    padding: 0.5rem 1rem;
                    &:focus {
                        box-shadow: none;
                    }
                }
                &-text {
                    color: #374047;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 0.5rem 1rem;
                    border: none;
                    background-color: lighten($color: #ffde11, $amount: 40);
                }
            }
            .btn {
                width: 100%;
                padding: 0.75rem;
                border-radius: 8px;
                a {
                    color: #010101;
                    font-weight: 600;
                    text-decoration: none;
                }
            }
        }
        &__card__footer {
            padding-bottom: 0.75rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            .rewards {
                p {
                    font-weight: 500;
                    font-size: 20px;
                    color: #374047;
                }
            }
            .btn {
                background-color: lighten($color: #1e282e, $amount: 80);
                border-radius: 50px;
                padding: 0rem 1rem;
                &:hover {
                    background: linear-gradient(90deg, #4d4d4d, #000);

                    a {
                        color: #fff;
                    }
                }
                a {
                    text-decoration: none;
                    color: #374047;
                }
            }
        }
    }
}
