.pagination{
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    border-radius: 6px;
    background:#272724;
    padding: 1rem;
    &--group{
        display: flex;
        gap: 0.25rem;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    &--item{
        padding: 0.4rem 0.9rem;
        border-radius: 4px;
        background: #333331;
        cursor: pointer;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        &.active{
            background-image: linear-gradient(to right, #FBB040 0%, #FFDE17 100%);
            color: #000;
            &:hover{
                background-image: linear-gradient(to right, #FBB040 0%, #FFDE17 100%);
            }
        }
        &:hover{
            background: #3d3d3b;
        }
        &.disabled{
            background: #3d3d3b;
            color: rgb(129, 129, 129);
            cursor: not-allowed;
        }
    }
}