.tab-pane {
    padding: 2rem 0;
    padding-top: 0;
    
}
.assets {
        &-content {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
            gap: 2rem;
        }
        &-card {
            width: 100%;
            margin: 0 auto;
            display: flex;
            border-radius: 8px;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            &:hover{
                .assets-img img{
                    transform: scale(1) !important;
                }
                .assets-card--body{
                    background-color: #2c2e31 !important;
                }
            }
            &--body{
                background-color: #272724;
                padding: 0.75rem;
                .buyBtn {
                    width: 40px;
                    height: 40px;
                    background: rgb(61, 58, 58);
                    border: none;
                    border-radius: 50%;
                    svg{
                        width:20px;
                        height: 20px;
                        fill: rgb(170, 170, 170);
                    }
                    &:hover{
                        background: rgb(95, 95, 95);
                        svg{
                            fill: rgb(255, 255, 255);
                        }
                    }
                    &.active{
                        background: #FBB040;
                        svg{
                            fill: #1e1f1d;
                        }
                    }
                }
            }
        }
        &-img {
            position: relative;
            font-weight: 600;
            display: flex;
            width: 100%;
            max-height: 200px;
            min-height: 200px;
            background: radial-gradient(white, #b87f7f00);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img{
                max-width: 200px;
                max-height: 200px;
                object-fit: contain;
                border-radius: 6px;
                overflow: hidden;
                transform: scale(0.9);
                transition: 0.2s ease-in-out all;
            }
            .icon {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        &-name {
            > div{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            h4 {
                font-weight: 600;
                margin: 0;
                color: #fff;
                margin-bottom: 0.5rem;
                cursor: pointer;
            }
            span {
                color: gray;
            }
            .price{
                background: rgb(61, 58, 58);
                width: fit-content;
                padding:  0.2rem 0.5rem;
                border-radius: 4px;
                img{
                    width: 16px;
                    height: 16px;
                    margin-right: 0.15rem;
                }
                span{
                    font-size: 14px;
                    font-weight: 600;
                    color: #f4b339;
                }
            }
        }
        &-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 0.5rem;
        }
    }
button{
    outline: none !important;
}
.buyBtn-group {
    button {
        border: none;
        background: rgb(61, 58, 58);
        border-radius: 50%;
        width: 36px;
        height: 36px;
        i {
            font-size: 12px;
            color: rgb(170, 170, 170);
        }
        &:hover{
            background: rgb(95, 95, 95);
            i{
                color: rgb(255, 255, 255);
            }
        }
    }
    span {
        padding: 0 8px;
    }
}
.lands {
    padding: 0 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    grid-gap: 2.5rem;
    @media (max-width: 768px) {
        padding: 0;
    }
    .card {
        border: none;
        border-radius: 5px;
        &-img-top {
            border-radius: 5px;
            height: 170px;
            object-fit: cover;
        }
        &-name {
            border-bottom: 1px solid #ffde17;
            h5 {
                font-weight: 600;
                font-size: 16px;
            }
        }
        &-title {
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            gap: 1.25rem;
            padding-top: 10px;
        }
        &-text {
            font-size: 14px;
            span {
                color: gray;
                font-size: 14px;
            }
            p {
                margin: 0;
                padding: 0;
                font-weight: 600;
            }
        }
        &-footer {
            border: none;
            background-color: #fff;
            padding-top: 0;
            padding-bottom: 1rem;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .btn {
                width: 100%;
            }
        }
    }
}


.noItem--card{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    max-width: 350px;
    margin-top: 4rem;
    background-color: #272724;
    padding: 2rem;
    border-radius: 8px;
    &:hover{
        background-color: #2c2c29 !important;
    }
    h4{
        text-align:center;
        opacity:0.9;
        font-size: 1.2rem;
        color: #fff;
    }
    .cart--icon{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: rgb(243, 243, 243);
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            width: 50px;
            height: 50px;
            path{
                fill: #FBB040;
            }
        }
    }
}