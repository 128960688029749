.card{
    box-shadow: 1px 2px 20px 0px #0000001a;
    border: 2px solid transparent;
    border-radius: 8px;
    transition: 0.3s ease-in-out all;
    &:hover{
        border-color: #FBB040;
    }
    .banner{
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        transition: 0.3s ease-in-out all;
        &::before{
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            transition: 0.3s ease-in-out all;
            background-image: linear-gradient(320deg, #f4b43a52, rgba(0, 0, 0, 0.267), #fede3133);
            position: absolute;
            z-index: 1;
            opacity: 0;
        }
        &:hover{
            .progressBox{
                opacity: 1;
            }
            &::before{
                opacity: 1;
            }
        }
    }
    .propertyImage{
        height: 240px;
        object-fit: cover;
        width: 100%;
        border-radius: 8px;
    }
}
.propertyHead{
    padding: 0.75rem;
    .propertyTitle{
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        h3{
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 18px;
            font-weight: 600;
            color: #374047;
            margin-bottom: 0.2rem;
        }
        span{
            color: #FBB040;
            font-weight: 600;
        }
    }
}
.progressBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 5;
    div{
        svg{
            text{
                fill:#fff !important;
            }
        }
    }

}

.propertyBody{
    padding: 0.75rem;
    padding-top: 1rem;
    ul{
        padding-left: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        row-gap: 0.25rem;
        li{
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 0.5rem;
            position: relative;
            align-items: center;
            padding-left: 1rem;
            border-bottom: 1px solid rgb(241, 241, 241);
            &:last-child{
                border-bottom: none;
            }
            &::before{
                content: "";
                display: block;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #374047;
                position: absolute;
                left: 0;
            }
            span{
                color: #FBB040;
                font-weight: 600;
            }
        }
    }
    .propertyButton{
        background-image: linear-gradient(to right, #FBB040 0%, #FFDE17 100%);
        color: #191e22;
        padding: 1rem 1.2rem;
        font-weight: 600;
        border: none;
        width: 100%;
        border-radius: 6px;
        display: block;
        text-align: center;
        text-decoration: none;
    }
}


.bannerDetails{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #000000b6;
    z-index: 5;
    .price{
        background-color: #f4b03d29;
        font-size: 1.25rem;
        font-weight: 600;
        padding: 0.75rem 1rem;
        padding-right: 2rem;
        color: #FBB040;
        border-top-right-radius: 50px;
        transition: all 0.3s ease-in-out;
    }
    .impression{
        padding: 0.5rem 1rem;
        padding-left: 2rem;
        padding-bottom: 0;
        text-align: center;
        border-top-left-radius: 50px;
        z-index: 5;
        transition: all 0.3s ease-in-out;
        h4{
            color: #FBB040;
            font-weight: 600;
            margin-bottom: 0;
        }
        span{
            font-size: 12px;
            font-weight: 600;
        }
    }
}

.timer{
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    z-index: 5;
    &::before{
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgb(37, 37, 37);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: blur(30px);
    }
    span{
        color: #d8d8d9;
        font-weight: 600;
    }
    p{
        background-color: #fcd83152;
        color: #fff;
        font-weight: 600;
    }
}

.disabled{
    background-image:linear-gradient(to right, #d5d5d5 0%, #cecece 100%) !important;
    color: #374047;
    cursor: not-allowed;
}