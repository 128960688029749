.from{
    width: 100%;
    padding: 0.754rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    > div{
        width: 100%;
    }
    .checkbox{
        display: flex;
        gap: 1rem;
        input[type="checkbox"]{
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 0.3rem;
            background: #fff;
            &:checked{
                background: #fff;
                + label{
                    &::after{
                        border: 6px solid #ffffff;
                        background-color: #FBB040;
                        border-radius: 4px;
                    }
                }
            }
            + label{
                padding-left: 39px; 
                &::after{
                    height: 25px;
                    width: 25px;
                    top: -4px;
                }
            }
        }
    }
    button{
        width: 100%;
        padding: 0.75rem 1rem;
        font-size: 1rem;
        color: rgb(53, 53, 53);
        border-radius: 0.3rem;
        font-weight: 600;
        outline: none;
        cursor: pointer;
        border: none;
        background-image: linear-gradient(to right, #FBB040 0%, #FFDE17 100%);
        &:disabled{
            background-image: linear-gradient(to right, #e4e4e4 0%, #e4e4e4 100%);
            color: darken(#e4e4e4, 50);
            cursor: not-allowed;
        }
    }
}

.dataList{
    padding-left: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 0.25rem;
    li{
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 0.5rem;
        position: relative;
        align-items: center;
        padding: 0.5rem;
        padding-left: 1rem;
        text-align: left;
        border-bottom: 1px solid rgb(241, 241, 241);
        &:last-child{
            border-bottom: none;
        }
        &::before{
            content: "";
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #374047;
            position: absolute;
            left: 0;
        }
        select, input{
            width: 137px;
            border: none;
            padding: 0.25rem 0.75rem;
            background: #f5f5f5;
            font-size: 1rem;
            color: #000;
            border-radius: 0.3rem;
            outline: none;
            &::placeholder{
                color: #000;
            }
        }
        .comingSoon{
            position: absolute;
            color: #fb5252;
            right: 10px;
            padding: 0;
            bottom: -15px;
            font-size: 14px;
            font-weight: 600;
            background-color: #fff;
        }
        .wallet{
            display: flex;
            align-items: center;
            .switch{
                border: none;
                background-color: #FBB040;
                display: flex;
                gap: 0.2rem;
                padding: 0.25rem 0.5rem;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
                padding-right: 0.75rem;
                position: relative;
                right: -0.5rem;
                z-index: 1;
                &:hover{
                    &::before{
                        opacity: 1;
                        top: -25px;
                    }
                }
                &::before{
                    content: "Switch to polygon";
                    position: absolute;
                    width: fit-content;
                    background-color: rgba(0, 0, 0, 0.63);
                    color: #eaf2ff;
                    font-size: 12px;
                    display: block;
                    white-space: pre;
                    top: -15px;
                    left: 50%;
                    pointer-events: none;
                    border-radius: 6px;
                    transform: translateX(-50%);
                    padding: 0.2rem 0.5rem;
                    transition: 0.2s all ease-in-out;
                    opacity: 0;
                }
            }
            .account{
                border: 1px solid #ffedcf;
                background-color: #ffedcf;
                padding: 0.3rem 0.5rem;
                border-radius: 6px;
                font-size: 14px !important;
                position: relative;
                z-index: 2;
                color: #FBB040;
                font-weight: 600;
            }
            .worng{
                border: 1px solid #ffeaea;
                background-color: #ffeaea;
                color: #fb5252;
            }
        }
    }
}
.title{
    font-size: 1.1rem;
    font-weight: 600;
    color: #000;
    margin-bottom: 1rem;
}


.warning{
    color: red;
}
