.marketplace{
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    &--sidebar{
        min-width: 275px;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        
    }
    &--content{
        display: flex;
        flex: 1 1 auto;
        .tab-pane{
            width: 100%;
        }
    }

    &--category{
        border-radius: 8px;
        padding: 0.5rem;
        padding-bottom: 0.75rem;
        background-color: $white;
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                padding: 0.65rem 1rem;
                border-radius: 4px;
                &:hover{
                    background-color: rgb(241, 241, 241);
                }
                .form-check-label{
                    cursor: pointer;
                    width: 100%;
                }
            }
        }
        .collections{
            &--item{
                position: relative;
                cursor: pointer;
                &.active{
                    background-color: rgb(241, 241, 241);
                    &::before{
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width:6px;
                        height: 100%;
                        background: linear-gradient(to right, #FBB040 0%, #FFDE17 100%);
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                    }
                }
            }
        }
    }
    .category{
        &--title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;
            cursor: pointer;
            position: relative;
            &::before{
                content: '';
                display: block;
                width: 100%;
                border-bottom: 1px solid rgb(241, 241, 241);
                position: absolute;
                bottom: 2px;
                left: 0;
            }
            h5{
                margin: 0;
                font-weight: 600;
                font-size: 1rem ;
                
            }
        }
    }
    &.content-tabs{
        padding: 1rem;
        background:#272724;
        border-radius: 6px;
        .filter{
            display: flex;
            gap: 1rem;
            align-items: center;
            width: 100%;
            &--item{
                border-radius: 6px;
                overflow: hidden;
                background: #333331;
                input{
                    background: transparent;
                    border: none;
                    padding:0.75rem 1rem;
                    background: #333331;
                    color: #fff;
                    outline: none;
                    width: 100%;
                    &:hover{
                        background: #3d3d3b;
                    }
                }
                &.search{
                    flex: 1 1 auto;
                    // width: 100%;
                    // max-width: 300px;
                    position: relative;
                    .filled{
                        position: absolute;
                        right: 0.4rem;
                        top: 50%;
                        transform: translateY(-50%);
                        border: none;
                        background: #454444;
                        cursor: pointer;
                        border: 50%;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        &:hover{
                            background: #FBB040;
                            svg{
                                fill: #1e1f1d;
                            }
                        }
                        svg{
                            width: 18px;
                            height: 18px;
                            fill: rgb(200, 200, 200);
                        }
                    }
                }
                &.collection{
                    min-width: 200px;
                    padding-right:0.5rem;
                    border-radius: 6px;
                    &:hover{
                        background: #3d3d3b;
                    }
                    select{
                        outline: none;
                        padding:0.75rem 1rem;
                        width: 100%;
                        background: transparent;
                        color: #fff;
                        border: none;
                        option{
                            background: #333331;
                            color: #fff;
                        }
                    }
                }
                &.price{
                    display: flex;
                    input{
                        width: 120px;  
                        -moz-appearance: textfield;
                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                    span{
                        background: #454444;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        padding: 0 0.5rem;
                    }                  
                }
                &.submit{
                    button{
                        padding:0.75rem 1rem;
                        font-size: 0.9rem;
                        display: flex;
                        gap: 0.5rem;
                        align-items: center;
                        svg{
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }
    }
}
.page--header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    h2{
        font-size: 1.25rem;
        font-weight: 600;
        color: #fff;
        margin-bottom: 0.25rem;
    }
    button{
        min-width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;
        background-color: #272724;
        cursor: pointer;
        svg{
            fill: rgb(200, 200, 200);
            width: 18px;
            height: 18px;
        }
        &:hover{
            background: rgb(95, 95, 95);
        }
        &.active{
            background: #FBB040;
            svg{
                fill: #1e1f1d;
            }
        }
    }
}
.filtered--row{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    color: #fff;
    margin-bottom: 1rem;
    h4{
        margin-bottom: 0;
        color: #fff;
        font-size: 1rem;
    }
    .filtered{
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        margin-left: 1rem;
        gap: 1rem;
        li{
            padding: 0.25rem 1rem;
            background: #3D3A3A;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 600;
            color: #FBB040;
        }
    }
}

.cart--btn{
    border: none;
    padding: 10px 10px;
    border-radius: 4px;
    position: relative;
    background:$white;
    span{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000;
        top: -12px;
        right:-12px;
        border-radius: 50%;
        background: #FBB040;
        font-size: 14px;
        width: 26px;
        height: 26px;
        line-height: 10px;
    }
}