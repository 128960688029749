@import "utilities/_index.scss";
@import "layout/_index.scss";
@import "components/_index.scss";
@import "pages/index.scss";

body {
        // font-family: "Montserrat", sans-serif;
}

p {
        padding: 0;
        margin: 0;
}

.footer-menu-nav {
        p {
                margin-bottom: 1rem !important;
        }
}

input[type="radio"]:checked + label::after {
        display: none !important;
}
input[type="radio"] + label::after {
        display: none !important;
}
.pools .stake .btn-group .btn {
        margin-bottom: 0;
}

.page{
&--header{
        margin-bottom: 2rem;
}
&--title{
        font-size: 1.8rem;
}
}