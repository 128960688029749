.section {
    &--hero {
        background-image: url(./../../../images/landing/hero-background.png);
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        min-height: 500px;
        align-items: flex-end;
        padding-top: 6rem;
        @media (max-width: 1024px) {
            align-items: center;
        }
        .hero--content {
            padding: 90px 30px 0 100px;
            padding-bottom: 10rem;
            @media (max-width: 1024px) {
                padding: 5rem;
                padding-bottom: 0rem;
                @media (max-width: 768px) {
                    padding: 4rem 2rem;
                    padding-bottom: 0rem;
                    @media (max-width: 425px) {
                        padding: 2rem 1rem;
                        @media (max-width: 320px) {
                            padding: 1rem 0;
                        }
                    }
                }
            }
            h1 {
                padding-top: 20px;
                font-size: 45px;
                font-weight: 600;
                color: #fbe539;
                @media (max-width: 1024px) {
                    font-size: 32px;
                    @media (max-width: 768px) {
                        font-size: 24px;
                    }
                }
            }
            p {
                color: #ffffff;
            }
        }
        .hero--img {
            @media (max-width: 1024px) {
                max-width: 500px;
                margin-left: auto;
                position: relative;
                top: -100px;
            }
            img {
                width: 100%;
            }
        }
    }
    &--tokenPrice {
        display: flex;
        justify-content: center;
        position: relative;
        top: -100px;
    }
}
.hero {
    background-image: url(./../../../images/landing/hero-background.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
    position: relative;
    margin-bottom: 6rem;
    @media (max-width: 1024px) {
        height: 450px;
    }
    &__text {
        padding: 90px 30px 0 100px;
        @media (max-width: 1024px) {
            padding: 60px 30px 0 20px;
        }
        @media (max-width: 768px) {
            padding: 0;
        }
        h1 {
            padding-top: 20px;
            font-size: 45px;
            font-weight: 600;
            color: #fbe539;
            @media (max-width: 1024px) {
                font-size: 32px;
            }
        }
        p {
            color: #ffffff;
        }
    }
    &__pic {
        background-image: url(../../../images/landing/hero-section-art.png);
        background-size: 40%;
        background-position-y: bottom;
        background-position-x: right;
        background-repeat: no-repeat;
        height: 490px;
        @media (max-width: 1024px) {
            background-size: 50%;
            background-position-y: -60%;
        }
    }
}
.tokenPrice {
    width: 70%;
    border-radius: 15px;
    background: white;
    padding: 20px 0 0 0;
    z-index: 9;
    box-shadow: 0px 27px 40px 0px #0000001f;
    @media (max-width: 1400px) {
        width: 80%;
        bottom: -120px;
        @media (max-width: 1200px) {
            width: 96%;
            bottom: -120px;
        }
    }

    .token {
        padding: 0 20px;
        &__name {
            display: flex;
            gap: 1rem;
            align-items: center;
            padding-bottom: 10px;
            p {
                font-weight: 500;
                font-size: 16px;
                color: #000;
            }
        }
        &__price {
            display: flex;
            align-items: center;
            gap: 25px;
            color: #111827;
            @media (max-width: 992px) {
                margin-bottom: 1rem;
                padding-left: 0.25rem;
            }
            p {
                font-size: 16px;
                font-weight: 600;
                color: #4eaf0a;
            }
            span {
                font-size: 22px;
                font-weight: 600;
            }
        }
    }
    &__list {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        @media (max-width: 992px) {
            flex-direction: column;
        }
    }
    &__price {
        padding: 20px;
    }
    &__total {
        padding: 0 20px;
        position: relative;
        border-left: 1px solid #ccc;
        @media (max-width: 992px) {
            border: none;
        }
        ul {
            margin: 0;
            padding: 0;
            position: relative;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 3rem;
                padding: 0 10px;
                padding-bottom: 16px;
                @media (max-width: 1024px) {
                    gap: 1rem;
                }
                @media (max-width: 1400px) {
                    gap: 2rem;
                }
                p {
                    font-weight: 600;
                    color: #1a1c20;
                }
            }
        }
    }
}
.tokenAddress {
    border-top: 1px solid #ccc;
    border-radius: 0 0 15px 15px;
    padding: 10px 40px;
    background: #f6f6f6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1024px) {
        padding: 10px;
    }
    .buy--btn {
        @media (max-width: 992px) {
            position: absolute;
            top: 2rem;
            right: 2rem;
            width: auto !important;
        }
    }
    &__itme {
        display: flex;
        gap: 2rem;
        @media (max-width: 1024px) {
            gap: 1rem;
            @media (max-width: 992px) {
                width: 100%;
                > div {
                    width: 100%;
                }
            }
            @media (max-width: 992px) {
                flex-direction: column;
            }
        }
    }
    &__input {
        display: flex;
        align-items: center;
        padding: 10px;
        gap: 1rem;
        border-radius: 8px;
        background: #fff;
        @media (max-width: 992px) {
            justify-content: space-between;
        }
        .copy-icon {
            display: flex;
            align-items: center;
            gap: 10px;
            p {
                color: #000;
            }
        }
        .copy-btn {
            border: none;
            background: transparent;
            cursor: pointer;
        }
    }
}

.TokenListed {
    padding: 30px 0;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__text {
        padding: 20px 0;
        h2 {
            font-size: 32px;
            font-weight: 700;
            text-align: center;
            @media (max-width: 768px) {
                font-size: 20px;
            }
        }
    }
    &__list {
        padding: 20px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        gap: 1.5rem;
    }
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        padding: 1rem;
        box-shadow: 0px 15px 29px #0000001a;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
        min-width: 150px;
        &:hover {
            a {
                display: block;
            }
        }
        img {
            width: 56px;
            border-radius: 10px;
        }
        a {
            display: none;
            padding: 0.5rem 1rem;
            color: #fff;
            text-decoration: none;
            font-size: 0.8rem;
            border-radius: 50px;
            background-color: #000;
        }
    }
}

.seection {
    &--world {
        padding: 4rem 2rem;
        @media (max-width: 768px) {
            padding: 3rem 1rem;
        }
        .world {
            padding: 0 1rem;
            &__img {
                max-width: 550px;
                width: 100%;
                margin: 0 auto;
                @media (max-width: 768px) {
                    padding-bottom: 3rem;
                    width: 80%;
                }
            }
            &__text {
                padding: 20px 100px 20px 100px;
                @media (max-width: 1024px) {
                    padding: 0 0 0 2rem;
                }
                @media (max-width: 768px) {
                    padding: 0px;
                }
                p {
                    padding: 0 0 20px 0;
                }
            }
        }
    }
}

.title-btn {
    border: 2px solid #ffde11;
}
.platform {
    background-image: url(../../../images/landing/aqarchain-symbol.png);
    background-size: 33%;
    background-position-y: 20%;
    background-position-x: -28%;
    background-repeat: no-repeat;
    &__con {
        padding: 4rem 2rem;
        @media (max-width: 768px) {
            padding: 3rem 1rem;
        }
    }
    &__img {
        max-width: 550px;
        width: 100%;
        margin: 0 auto;
        @media (max-width: 768px) {
            padding-bottom: 3rem;
            width: 80%;
        }
    }
    &__text {
        padding: 20px 100px 20px 100px;
        @media (max-width: 1024px) {
            padding: 0px 30px 0px 0px;
        }
        @media (max-width: 768px) {
            padding: 0px;
        }

        p {
            padding: 0 0 20px 0;
        }
    }
}

.order2 {
    @media (max-width: 768px) {
        order: 2;
    }
}
.order1 {
    @media (max-width: 768px) {
        order: 1;
    }
}
.metaverse {
    margin-top: 4rem;
    background-color: #1a1c20;
    @media (max-width: 768px) {
        padding-bottom: 4rem;
    }

    &__bg-shep {
        position: relative;
        @media (max-width: 768px) {
            margin-bottom: 2rem;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 120px;
            width: 100%;
            height: 100%;
            background-image: url(../../../images/landing/metaverse-BG.svg);
            background-size: contain;
            background-position-y: top;
            background-position-x: left;
            background-repeat: no-repeat;
            opacity: 0.4;
            @media (max-width: 768px) {
                right: 0px;
            }
        }
    }
    h2 {
        color: #fbb042;
    }
    p {
        color: #fff;
        padding-bottom: 1.5rem;
    }
    button {
        color: #fff;
    }
    &__text {
        padding: 0rem 8rem 0 4rem;
        @media (max-width: 1024px) {
            padding: 0rem 2rem 0 2rem;
            @media (max-width: 768px) {
                padding: 0 1rem;
            }
        }
    }
    &__img {
        max-width: 400px;
        width: 100%;
        margin-left: auto;
        margin-right: 5rem;
        margin-top: -4rem;
        margin-bottom: -4rem;
        z-index: 2;
        position: relative;
        @media (max-width: 768px) {
            margin: 0 auto;
            width: 60%;
        }
    }
}

.playEarn {
    &__con {
        padding: 6rem 2rem;
        @media (max-width: 768px) {
            padding: 4rem 1rem;
        }
    }
    &__text {
        padding: 20px 100px 20px 100px;
        @media (max-width: 768px) {
            padding: 20px 10px 50px 10px;
        }
        @media (max-width: 1024px) {
            padding: 0px;
        }
    }
    &__img {
        max-width: 550px;
        width: 100%;
        margin: 0 auto;
        @media (max-width: 768px) {
            margin-bottom: 3rem;
            width: 80%;
        }
    }
}

.Ownership {
    &__con {
        padding: 4rem 2rem;
        @media (max-width: 768px) {
            padding: 4rem 0rem;
        }
    }
    &__text {
        padding: 20px 100px 20px 100px;
        @media (max-width: 1024px) {
            padding: 0px 0px 0px 0px;
            @media (max-width: 768px) {
                padding: 0 1rem;
            }
        }
    }
    &__bg-shep {
        position: relative;
        padding: 3rem 0;
        &::before {
            content: "";
            position: absolute;
            top: 0px;
            right: 140px;
            width: 100%;
            height: 100%;
            background-image: url(../../../images/landing/ownership-BG.svg);
            background-size: contain;
            background-position-y: top;
            background-position-x: left;
            background-repeat: no-repeat;
            z-index: -1;
            @media (max-width: 768px) {
                right: 0px;
                width: 130%;
            }
        }
    }
    &__img {
        max-width: 450px;
        width: 100%;
        margin-left: auto;
        margin-right: 2rem;
        @media (max-width: 768px) {
            margin-bottom: 3rem;
            margin: 0 auto;
            width: 80%;
        }
    }
}

.ownershipWork {
    background-image: url(../../../images/landing/aqarchain-symbol.png);
    background-size: 30%;
    background-position-y: -75%;
    background-position-x: 126%;
    background-repeat: no-repeat;

    &__con {
        padding: 4rem 2rem;
        @media (max-width: 768px) {
            padding: 3rem 1rem;
        }
    }
    &__text {
        padding: 20px 100px 20px 100px;
        @media (max-width: 768px) {
            padding: 20px 10px 50px 10px;
        }
        @media (max-width: 1024px) {
            padding: 0px;
        }
    }
    &__img {
        max-width: 450px;
        width: 100%;
        margin: 0 auto;
        @media (max-width: 768px) {
            margin-bottom: 3rem;
            width: 80%;
        }
    }
}

.cricket {
    padding: 4rem;
    @media (max-width: 768px) {
        padding: 3rem 0rem;
    }
}
.TokenUtility {
    padding: 30px;
    background: #1a1c20;
    background-image: url(../../../images/landing/aqarchain-symbol.png);
    background-size: 24%;
    background-position-y: 135%;
    background-position-x: -20%;
    background-repeat: no-repeat;
    @media (max-width: 768px) {
        padding: 0 10px;
    }
    &__text {
        padding: 20px 100px 20px 100px;
        padding-right: 0;
        @media (max-width: 768px) {
            padding: 20px 10px 20px 10px;
        }
        @media (max-width: 1024px) {
            padding: 0px;
        }
        h2 {
            color: #fbb042;
            margin: 10px 0 !important;
        }
        p {
            color: #fff;
        }
    }
    &__img {
        margin-top: -50px;
        @media (max-width: 1024px) {
            width: 100% !important;
        }
    }
    .payments {
        padding: 30px 0;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
            padding-bottom: 1rem;
            align-items: center;
            @media (max-width: 425px) {
                grid-template-columns: repeat(1, 1fr);
            }
            li {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 10px;
                img {
                    @media (max-width: 768px) {
                        width: 20px;
                    }
                }
            }
        }
    }
}

.AqarchainNews {
    padding: 30px;
    background: #1a1c20;
    &__text {
        font-size: 32px !important;
        color: #fbb042 !important;
    }
}

.PartnersBy {
    padding: 60px 0;

    background: #1a1c20;
    &__text {
        font-size: 32px;
        color: #fbb042 !important;
        @media (max-width: 768px) {
            font-size: 20px;
            margin: 0 !important;
        }
    }
}

.image {
    &--group {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        padding: 4rem;
        padding-bottom: 6rem;
        gap: 2rem;
        @media (max-width: 425px) {
            grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
            padding: 2rem 1rem;
            padding-bottom: 4rem;
            gap: 1rem;
            > div {
                img {
                    width: 100% !important;
                }
            }
        }
        > div {
            display: flex;
            justify-content: center;
            img {
                width: 80%;
            }
        }
    }
}

.content-subheader {
    @media (max-width: 768px) {
        font-size: 20px;
    }
}
